
/**
 * @name: 已退款,待审核 -----详情
 * @author: lili
 * @date: 2023-07-24 14:07
 * @description：已退款,待审核 -----详情
 * @update: 2023-07-24 14:07
 */

import {Component, Vue} from "vue-property-decorator"
import {ICrudOption} from "@/types/m-ui-crud"
import {orderRefundDetailApi, orderRefundGoodsListApi} from "@/apis/afterSales/refunded"
import config from "@/config";
import {IOrderRefunded, RootObjectOrderRefundGoodsVoList} from "@/apis/afterSales/refunded/types";

@Component({})
export default class DetailsPage extends Vue {
  config = config;
  // 表格加载状态
  tableLoading: boolean = false;
  amount: string = ''
  // 表格数据
  tableData: any[] = []
  // 表格数据
  tableTotal: number = 0
  // 查询参数
  id: string = ''
  // 查询参数
  queryForm: any = {
    page: 1,
    limit: 10,
  }
  // 表单数据
  modelForm: Partial<IOrderRefunded> = {}
  // 表单数据
  detailForm: Partial<IOrderRefunded> = {
    orderRefundDtlVoList: []
  }

  // 商品明细
  detailDialog = false
  goodsDetailData: Partial<RootObjectOrderRefundGoodsVoList[]> = []

  // 配置信息
  crudOption: ICrudOption = {
    menu: false,
    searchBox: false,
    column: [
      {
        label: "审核时间",
        prop: "examineTime",
        align: "center",
      },
      {
        label: "经办人",
        prop: "handledName",
        align: "center",
      },
      {
        label: "审核结果",//状态: 1=待审核 2=已通过 3=已拒绝
        prop: "status",
        align: 'center',
        type: 'select',
        dicData: [
          {
            label: "已拒绝",
            value: 3
          },
          {
            label: "已通过",
            value: 2
          },
          {
            label: "待审核",
            value: 1
          },
        ]
      },
      {
        label: "审核拒绝理由",
        prop: "refuseReason",
        overHidden: true,
        align: "center",
      },
      {
        label: "订单售后原因",
        prop: "refundReason",
        overHidden: true,
        align: "center",
      },
      {
        label: "售后凭证",
        prop: "refundPic",
        type: 'image',
        align: 'center',
        imgPrefix: config.downloadUrl,
        imgSuffix:';',
      },

      {
        label: "售后商品",
        prop: "goods",
        align: 'center',
        slot: true,
      },
    ]
  }

  /**
   * 查看详情
   */
  openDetail() {
    orderRefundDetailApi(this.id as string).then(e => {
      if (e) {
        this.detailForm = e;
      }
    })
  }

  /**
   * 查看商品详情
   */
  openGoodsDetail(row: IOrderRefunded, index: number) {
    orderRefundGoodsListApi({
      orderRefundId:this.detailForm.id,
      orderRefundDtlId:row.id
    }).then(e => {
      if (e) {
        this.goodsDetailData =e;
        this.detailDialog = true;
      }
    })
  }

  created() {
    const {id} = this.$route.query;
    this.id = id;
    this.openDetail()
  }
}
